// custom typefaces
import * as React from "react";
import "typeface-montserrat";
import "typeface-merriweather";
import "typeface-nunito";
import "typeface-alegreya";
import "./src/components/ImageCarousel/carousel.css";
import { StoreProvider } from "./src/provider/StoreProvider";

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);
